<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      translatePrefix="controlTower.pages"
    />
  </div>
</template>

<script>
import { CargillCrudMetaView, helpers } from '@cargill/shared'
import service from '../api/takeUpSchedulesFarmReportLog'
import { CrudAction } from '@brain/core'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service
    }
  },
  methods: {
    async getMeta() {
      const meta = await service.getMeta()
      meta.permissions = [CrudAction.EXPORT]
      return meta
    },
    getGridComponent() {
      return helpers.findFirstComponent(this, ['BcGrid', 'CgGrid'])
    },
    onGridReady() {
      // const gridOptions = this.getGridComponent().aggridOptions
      // gridOptions.api.setSortModel([{ colId: 'date', sort: 'desc' }])
      // gridOptions.api.onFilterChanged()
    }
  },
  created() {
    this.getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>
